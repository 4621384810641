@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&family=Ubuntu&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --COLOR-PRIMARY: black;
  --COLOR-SECONDARY: #1792d2;
  --COLOR-LIGHT: #f8f8f8;
  --COLOR-DARK: #143774;
  --COLOR-RED: #F83735;
}

body {
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  padding: 1em;
}

header {
  text-align: center;
  background-color: var(--COLOR-LIGHT);
}

nav {
  font-size: 1rem;
  width: 100%;
  padding: 1.5rem 0;
}

h2 {
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: 900;
  position: relative;
  margin-top: 3rem;
}

nav > ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-around;
  margin: 0.5rem auto;
  width: 700px
}

p {
  font-size: 20px;
  margin: 0 0 1.5rem;
  line-height: 1.5;
  font-weight: 300;
}

a {
  color: var(--COLOR-RED);
  transition: color .2s ease-in-out;
  -moz-transition: color .2s ease-in-out;
  text-decoration: underline;
  -webkit-text-decoration-ink: auto;
  text-decoration-skip-ink: auto;
  font-weight: bold;
}

nav li {
  margin: 0.75rem 0.75rem;
}



nav a {
  text-decoration: none;
  font-weight: bold;
  color: var(--COLOR-PRIMARY);
  padding: 0.25rem 0;
  font-size: 18px;
  position: relative;
  border-bottom: none; 
  margin: 0;
}

nav a:hover {
  color: black;
  background: white;
  z-index: 0;
}

/*nav a:hover,
nav a:focus {
  color: var(--COLOR-SECONDARY);
}*/

nav a:hover::before,
.dotted::before {
  position: absolute;
  display: block;
  left: -20px;
  top: 10px;
  content: '';
  height: 100%;
  width: 120%;
  background-image: radial-gradient(var(--COLOR-RED) 25%, white 40%);
  background-position: 0px 0px;
  background-size: 6px 6px;
  z-index: -1;
}

.intro {
  position: relative;
  min-height: 460px;
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro > .intro-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 700px;
  max-width: 100%;
  position: relative;
}

.intro-p {
  margin-right: 2.5rem;
  max-width: 50%;
}

.intro p {
  margin-top: 2.2rem;
  font-size: 1.4em;
}

.intro h1 {
  color: black;
  background-image: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: inherit;
  font-size: 50px;
  line-height: 50px;
  margin: 0.75rem 0;
  padding: 0;
}

.react-p5 {
  min-width: 340px;
  min-height: 340px;
  height: 100%;
  max-width: 90%;
  overflow: hidden;
  position: relative;
}

.art-legend {
  text-align: center;
}

.art-legend button {
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

.art-legend button>b {
  color: var(--COLOR-RED);
}

.content {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 36px;
  margin-bottom: 48px;
  padding: 0;
  width: inherit;
}

.with-under {
  position: relative;
}

.with-under:after {
  content: "";
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: calc(0px - 12px);
    left: 0;
    border-bottom: 6px solid var(--COLOR-RED);
}

.content .listing {
  margin: 0;
  padding: 0;
}

.content ul,
.content ol {
  font-size: 20px;
}

.content li {
  margin-bottom: 12px;
  margin-left: 12px;
  line-height: 1.5;
}


.content .listing.table li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.content .listing li {
  font-size: 16px;
  list-style: none;
  padding: 5px 3px;
  margin: 0;
  color: black;
}

.content .listing.table li>a {
  flex-shrink: 0;
  flex-grow: 0;
  width: 40%;
}

.content .listing.table li>div {
  flex-grow: 1;
  text-transform: none;
  letter-spacing: 0;
  margin-left: 8px;
}

.content .listing.table li>div:before {
  content: '→ ';
  font-weight: bold;
}

.content .listing.fancy a {
  color: black;
  padding-bottom: 2px;
  text-decoration: none;
  font-weight: normal;
  position: relative;
}

.content .listing li span {
  font-size: 14px;
  text-align: right;
  letter-spacing: 2px;
  flex-shrink: 0;
  text-transform: uppercase;
}

.home_link a {
  font-size: 22px;
}

.react-p5 canvas {
  cursor : pointer;
  user-select: none;
}

.content hr {
  background-color: #FAC832;
  border: 0px;
  margin: 50px auto;
  width: 50%;
  height: 5px;
  border-radius: 5px;
  max-width: 430px;
  clear: both;
}

.animation {
  min-width: 340px;
    min-height: 340px;
    height: 100%;
    max-width: 90%;
    margin-top: 48px;
    margin-bottom: 48px;
    overflow: hidden;
    position: relative;
}

.art-legend button:hover+.tooltip {
  display: block;
}

.tooltip {
  position: absolute;
  top: 30%;
  left: 10%;
  width: 80%;
  padding: 12px;
  background: white;
  border: 4px solid var(--COLOR-RED);
  display: none;
}

@media screen and (max-width:600px) {
  .content .listing.table li {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: var(--spacing-m);
  }

  .content .listing.table li>div {
    margin-left: 0;
  }

  .content .listing.table li>a {
    width: 100% !important;
    flex-shrink: 0;
  }

  .intro>.intro-wrapper {
      flex-wrap: wrap;
      justify-content: center;
  }

  .intro-p {
      margin-right: 0;
      max-width: 100%;
  }

}
